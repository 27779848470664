.Home .lander {
  padding: 80px 0;
  text-align: center;
}

.Home .lander div {
  padding-top: 20px;
}
.Home .lander div a:first-child {
  margin-right: 20px;
}

.Home .one-goal {
  width:100%;
  margin-bottom:3vw;
}

@media (min-width: 768px) {
  .Home .one-goal {
    width:31%;
  }
/*
  .Home .one-goal:nth-child(3n+1):nth-last-child(-n+3) ~ .one-goal {
    margin-left:calc(31% * .176);
    margin-right: auto;
  }

  .Home .one-goal:nth-child(3n+2):nth-last-child(-n+3):last-child {
    margin-left:-31%;
  }
*/
}

.Home .cat-holder { position:relative; }
.Home .bright-cat { position:absolute; left:0; display: inline-block; overflow: hidden; }

.ViewGoal h1 small {
  font-size:50%;
}

.LoaderButton .spinning.glyphicon {
  margin-right: 7px;
  top: 2px;
  -webkit-animation: spin 1s infinite linear;
          animation: spin 1s infinite linear;
}
@-webkit-keyframes spin {
  from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
  to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}
@keyframes spin {
  from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
  to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}

.EditGoal form {
  padding-bottom: 15px;
}

.EditGoal form textarea {
  height: 100px;
}

@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
}

@media all and (min-width: 480px) {
  .Signup {
    padding: 60px 0;
  }

  .Signup form {
    margin: 0 auto;
    max-width: 320px;
  }
}

.Signup form span.help-block {
  padding-bottom: 10px;
}

@media all and (min-width: 480px) {
    .ResetPassword {
      padding: 60px 0;
    }
  
    .ResetPassword form {
      margin: 0 auto;
      max-width: 320px;
    }
  
    .ResetPassword .success {
      max-width: 400px;
    }
  }
  
  .ResetPassword .success {
    margin: 0 auto;
    text-align: center;
  }
.NewGoal form {
  padding-bottom: 15px;
}

.NewGoal form textarea {
  height: 100px;
}

.Progress form {
  padding-bottom: 15px;
}

.Progress form textarea {
  height: 100px;
}

.NotFound {
  padding-top: 100px;
  text-align: center;
}

.App {
  margin-top: 15px;
}

.App .app-title { margin-top:5px; display:inline-block; font-weight: bold; }
.App .navbar { margin-bottom:20px; }

.App .version { position:absolute; top:2px; right:4px; font-size:0.7em; color:white; text-align:right; }
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
input[type=file] {
  width: 100%;
}

