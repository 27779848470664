.Home .lander {
  padding: 80px 0;
  text-align: center;
}

.Home .lander div {
  padding-top: 20px;
}
.Home .lander div a:first-child {
  margin-right: 20px;
}

.Home .one-goal {
  width:100%;
  margin-bottom:3vw;
}

@media (min-width: 768px) {
  .Home .one-goal {
    width:31%;
  }
/*
  .Home .one-goal:nth-child(3n+1):nth-last-child(-n+3) ~ .one-goal {
    margin-left:calc(31% * .176);
    margin-right: auto;
  }

  .Home .one-goal:nth-child(3n+2):nth-last-child(-n+3):last-child {
    margin-left:-31%;
  }
*/
}

.Home .cat-holder { position:relative; }
.Home .bright-cat { position:absolute; left:0; display: inline-block; overflow: hidden; }
